(function($, w, undefined) {
    function ContentTileSwiper(args) {
        this.defaults = {
            elem: '',
            swiperContainer: '.swiper-container',
            swiperOptions: {

                loop: false,
                slidesPerView: 1,
                spaceBetween: 24,
                simulateTouch: true,

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                pagination: {
                    el: '.swiper-pagination',
                },

                // Responsive breakpoints
                breakpointsInverse: true,
                breakpoints: {
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 24,
                        simulateTouch: false
                    }
                }
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.swiper = null;

        this.$elem = $(this.options.elem);

        if(!this.$elem.length > 0){
            return;
        }

        this.$swiperContainer = $(this.parent(this.options.swiperContainer));
        this.initSwiper();
    };

    ContentTileSwiper.prototype.parent = function (selector) {
        return $(selector, this.$elem);
    };

    ContentTileSwiper.prototype.initSwiper = function (){
        var desktopActive = Bio.Utility.StateManager.ssm.isActive('desktop');
        if(desktopActive){
            this.options.swiperOptions.simulateTouch = false;
        }
        this.swiper = new Swiper(this.$swiperContainer[0], this.options.swiperOptions);
    };



    w.Bio= w.Bio|| {};
    w.Bio.Modules = w.Bio.Modules || {};
    w.Bio.Modules.ContentTileSwiper = ContentTileSwiper;

})(jQuery, window);
