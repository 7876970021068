var Bio = Bio || {};

(function($, jQuery) {
	'use strict';

	Bio.Video = function( options ) {

		var settings = {

			// Elements
			el: {
				wrapper: ".video-wrap",
			},

		},

		Video = {

	      init: function () {

	        if(!$(settings.el.wrapper).attr('data-url')) return;

	        $(settings.el.wrapper).each(function() {
	          var $this = $(this);
	          var videoId = Video.getID($this.attr('data-url'));
	          if (!videoId) return;
	          $this.html('<iframe width="560" height="315" src="//www.youtube.com/embed/' + videoId + '?autoplay=0&showinfo=1&controls=1&rel=0" frameborder="0" allowfullscreen></iframe>');
	        });

	      },

	      getID: function (url) {

	        if (!url) return false;

	        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
	            match = url.match(regExp);

	        if (match && match[2].length == 11) return match[2];
	        else return false;

	      }
	  };

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {
			Video.init();
		}

		__construct();


		//
		// Make some events accessible from global scope
		//
		return {

		};
	};


})(jQuery, jQuery);
