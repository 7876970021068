(function($, w, undefined) {
    function ProductScrollAnchors(args) {
        this.defaults = {
            elem: '.product-scroll-anchors',
            btnClose: '.btn-close',
            cookieName: 'BioIsland.GlobalAlertClosed'
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$elem = $(this.options.elem);
        if(!this.$elem.length > 0){
            return;
        }

        /*
         Define nodes / elements
         */
        this.$links = $(this.parent('a'));

        $(this.$links).each(function() {
            var $this = $(this);

            $this.click(function () {

                var targetId = $this.attr('href');
                var targetElem = document.getElementById(targetId);
                var offset = targetElem.offsetTop;

                window.scroll({
                    top: offset,
                    behavior: 'smooth'
                });

                return false;
            });
        });
    };

    ProductScrollAnchors.prototype.parent = function (selector) {
        return $(selector, this.$elem);
    };


    w.Bio= w.Bio|| {};
    w.Bio.Modules = w.Bio.Modules || {};
    w.Bio.Modules.ProductScrollAnchors = ProductScrollAnchors;

})(jQuery, window);
