var Bio = Bio || {};

(function($, jQuery) {
	'use strict';

	Bio.Geo = function( options ) {

		var settings = {
			cookie: "geo-location",
		  	api: "https://freegeoip.net/json/"
		},
		Geo = {
		  get: function (callback) {

		    settings.location = Bio.Cookie().get(settings.cookie);

		    if(settings.location) return complete();

		    $.ajax({
		      url: settings.api,
		      jsonp: "callback",
		      dataType: "jsonp",
		      success: complete
		    })
		    .fail(callback);

		    function complete (data) {

		      if(data && data.country_code) {
		        settings.location = data;
		        Bio.Cookie().set(settings.cookie, data, 30, false);
		      }

		      if(callback) callback(Geo.location());
		    }
		  },

		  location: function () {
		    return _.extend({}, settings.location);
		  }

		};

		/**********************************
		// Simple constructor
		**********************************/
		// function __construct() {

		// }

		// __construct();


		//
		// Make some events accessible from global scope
		//
		return Geo
	};


})(jQuery, jQuery);
