(function($, w, undefined) {
    function ProductHeroPanel(args) {
        this.defaults = {
            elem: '.product-hero-panel',
            scrollBtn: '.btn-scroll',
            defaultSpeed: 700,
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$elem = $(this.options.elem);

        if(!this.$elem.length > 0){
            return;
        }

        this.$scrollBtn = $(this.parent(this.options.scrollBtn));
        this.$productImg = $(this.parent('img'));

        var self = this;
        this.$scrollBtn.click(function (event) {
            self.scrollPage();
        });

        objectFitImages(this.$productImg);
    };

    ProductHeroPanel.prototype.parent = function (selector) {
        return $(selector, this.$elem);
    };

    ProductHeroPanel.prototype.scrollPage = function (){
        var targetDOMElem = this.$elem.next();
        var elemPos = targetDOMElem.position();

        $('html, body').animate({
            scrollTop: elemPos.top
        }, this.options.defaultSpeed);
    };


    w.Bio= w.Bio|| {};
    w.Bio.Modules = w.Bio.Modules || {};
    w.Bio.Modules.ProductHeroPanel = ProductHeroPanel;

})(jQuery, window);
