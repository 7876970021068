var Bio = Bio || {};

(function ($, jQuery) {
    'use strict';

    Bio.Map = function (options) {
        function debugLog(section, value) {
            if (console && console.log) {
                console.log(section, value);
            }
        }

        var autocomplete;
        var settings = {
                allStoreData: [],
                currentLocations: [],
                viewList: [],
                currentStart: 0,
                currentResultSize: 10,

                country: "AU",
                countryRestrictions: ['au', 'nz', 'cn'],
                showing: 0,
                stores: "/v1/AngularGoogleMapApi/GetAllRetailers",
                // stores: "/static/data/stores.json",
                //stores: "/static/data/stores-staging.json",
                //stores: "/static/data/stores.txt",
                storeData: [],
                toggleState: 'map',
                removeSearchString: true,

                // Elements
                el: {
                    wrap: '.retailers',
                    map: "#map-placeholder .map",
                    locateMe: '.button--near-me',
                    mapSearchSection: '.map-search',
                    findAddress: '.map-search__address-form',
                    address: '.map-search__address',
                    loading: '.google-map__loading',
                    resultsList: '.location-results .map__results-list',
                    resultsScroll: '.map__results-list',
                    results: '.location-results',
                    toggle: '.maptoggle a',
                    showMoreBtn: '.show-more',
                    filter: '.filter select',
                    mobileMenu: '.map-mobile-menu',
                    storeName: '.store-name',
                    countrySelect: '.country-select select',
                    searchField: 'map-search'

                },

                // variables for fixed map menu in mobile (created on load)
                fixedMenu: {
                    offset: null,
                    menuHeight: null,
                    retailerHeight: null,
                    length: null,
                },

                // Map
                map: {
                    view: {},
                    geocoder: {},
                    infoWindow: {},
                    clusterManager: {},
                    countryBounds: false,
                    minZoom: $(window).width() < 768 ? 2 : 3,
                    maxZoom: 13,
                    placeID: 0,
                    // defaultMarkerImage: '/static/imgs/archive/marker.php',
                    defaultCustomMarkerImage: '/static/imgs/marker-custom.svg',

                    clusterIcons: [{
                        textColor: 'white',
                        url: '/static/imgs/archive/cluster-icon.png',
                        height: 60,
                        width: 60
                    }],

                    settings: {
                        zoom: 5,
                        center: {},
                        disableDefaultUI: true,
                        zoomControl: true,
                        gestureHandling: 'cooperative',
                        styles: [
                            {
                                "featureType": "administrative",
                                "elementType": "labels.text.fill",
                                "stylers": [{"color": "#444444"}]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.fill",
                                "stylers": [{"visibility": "off"}]
                            },
                            {"featureType": "landscape", "elementType": "all", "stylers": [{"color": "#f7f7f7"}]},
                            {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"}]},
                            {
                                "featureType": "road",
                                "elementType": "all",
                                "stylers": [{"saturation": -100}, {"lightness": 45}]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "all",
                                "stylers": [{"visibility": "simplified"}]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.icon",
                                "stylers": [{"visibility": "off"}]
                            },
                            {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"}]},
                            {
                                "featureType": "water",
                                "elementType": "all",
                                "stylers": [{"color": "#e6e6e6"}, {"visibility": "on"}]
                            }
                        ]
                    },
                },

                // Templates
                templates: {
                    loadingTemplate: '<div class="google-map__info-window">Loading</div>',
                    infoWindowTemplate: _.template([
                        '<div class="google-map__info-window">',
                        '<h3><%= RetailerName %></h3>',
                        '<p><%= fullAddress %></p>',
                        '<span class="directions"><a href="https://maps.google.com?saddr=Current+Location&daddr=<%= Latitude %>,<%= Longitude %>" target="_blank" data-ga="cta|click|Directions - <%= fullAddress %> ">Get Directions <svg class="icon"><use xlink:href="/static/svg/sprite.svg#arrow-right"></use></svg></a></span>',
                        '</div>'
                    ].join("\n")),
                    listTemplate: _.template([
                        '<% _.each(locations, function(location) { %>',
                        '<% if (location.Group !== currentstate) { %>',
                        '<li class="state"><%= location.Group %></li>',
                        '<% currentstate = location.Group %>',
                        '<% } %>',
                        '<li>',
                        '<h4><a class="store-name" href="#" data-store-lat="<%= location.Latitude %>" data-store-lng="<%= location.Longitude %>" data-store-id="<%= location.Id %>"><%= location.RetailerName %></a></h4>',
                        '<p>',
                        '<%= location.fullAddress %>',
                        '<% if (location.Phone) { %><span class="phone"><svg class="icon"><use xlink:href="/static/svg/sprite.svg#phone"></use></svg>Phone: <a href="tel:<%= location.Phone %>"><%= location.Phone %></a></span><% } %>',
                        '<% if (location.Fax) { %><span class="fax"><svg class="icon"><use xlink:href="/static/svg/sprite.svg#fax"></use></svg>Fax: <%= location.Fax %></span><% } %>',
                        '<span class="directions"><a href="https://maps.google.com?saddr=Current+Location&daddr=<%= location.Latitude %>,<%= location.Longitude %>" target="_blank" data-ga="cta|click|Directions - <%= location.fullAddress %> ">Get Directions <svg class="icon"><use xlink:href="/static/svg/sprite.svg#arrow-right"></use></svg></a></span>',
                        '</p>',
                        '</li>',
                        '<% }) %>'
                    ].join("\n")),
                },

                locations: {
                    markers: []
                }
            },
            fn = {
                ready: function (e) {
                    /**
                     * @function init
                     */

                    delete window._mapReady;
                    settings.ready = true;
                    settings.mobile = fn.isMobile();

                    // Set up map properties
                    settings.map.geocoder = new google.maps.Geocoder();
                    settings.map.infoWindow = new google.maps.InfoWindow();
                    settings.map.settings.center = new google.maps.LatLng(-33.9, 151.2);
                    settings.map.defaultMarker = {
                        url: settings.map.defaultMarkerImage,
                        size: new google.maps.Size(30, 40),
                        scaledSize: new google.maps.Size(30, 35)
                    };
                    // Set the map view
                    settings.map.view = new google.maps.Map($(settings.el.map)[0], settings.map.settings);

                    //fn.fitToAddress(settings.country, function () {

                    // Get the locations
                    fn.getLocations(function (response) {
                        // Hide loading state
                        $(settings.el.loading).removeClass('is-visible');

                    });

                    //});


                    // jQuery Events
                    // $(settings.el.locateMe).on('click', fn.locate);
                    $(settings.el.findAddress).on('submit', fn.on.addressSubmit)
                        .on('focusin', '.is-invalid input', fn.focus);
                    $(settings.el.address).on('keyup', fn.on.addressKeyup);

                    $(settings.el.resultsList).on('click', settings.el.storeName, function (e) {
                        var lat = $(this).attr('data-store-lat');
                        var lng = $(this).attr('data-store-lng');
                        var storeID = $(this).attr('data-store-id');

                        fn.showLocation(lat, lng, storeID);
                        e.preventDefault();
                    });


                    $(settings.el.toggle).on('click', fn.toggle);
                    $(settings.el.showMoreBtn).on('click', fn.loadMore);
                    $(settings.el.filter).on('change', fn.eventFilterState);
                    $(settings.el.countrySelect).on('change', fn.selectCountry);
                    $(window).on('resize', fn.resize);
                    $(window).on('scroll', fn.updateFixedMenu);

                    $('.custom-select-wrapper select').select2({
                        minimumResultsForSearch: 15,
                        width: '100%',
                        theme: "custom"
                    });

                    $('.select2-selection__arrow').append('<svg class="icon icon--down"><use xlink:href="/static/svg/sprite.svg#arrow-down"></use></svg><svg class="icon icon--up"><use xlink:href="/static/svg/sprite.svg#arrow-up"></use></svg>');

                    // add listeners
                    fn.initListeners();

                },

                focus: function (e) {
                    $(e.target).parent().removeClass('is-invalid');
                },

                isMobile: function () {
                    return $(window).width() < 768;
                },

                calculateCenter: function () {
                    settings.center = settings.map.view.getCenter();
                },

                updateFixedMenu: function () {
                    if (!fn.isMobile()) return $(settings.el.mobileMenu).css({position: "relative", "top": "initial"});

                    var currentScroll = $(document).scrollTop(),
                        offset = $(settings.el.mapSearchSection).offset().top + $(settings.el.mapSearchSection).outerHeight(),
                        menuHeight = $(settings.el.mobileMenu).outerHeight(),
                        retailerHeight = $(settings.el.wrap).outerHeight();

                    if (settings.toggleState == 'map') length = $(settings.el.map).outerHeight();
                    else length = $(settings.el.results).outerHeight();

                    if (currentScroll >= (offset + length - menuHeight)) {
                        var top = retailerHeight - menuHeight;
                        $(settings.el.mobileMenu).css({position: "absolute", "top": top});
                    } else if (currentScroll >= offset) {
                        $(settings.el.mobileMenu).css({position: "fixed", "top": "0"});
                    } else {
                        $(settings.el.mobileMenu).css({position: "absolute", "top": "initial"});
                    }
                },

                resize: function () {
                    var mobile = fn.isMobile();

                    fn.updateFixedMenu();

                    if (mobile == settings.mobile) return;
                    else settings.mobile = mobile;

                    fn.on.updateResults();
                },

                toggle: function (e) {
                    e.preventDefault();

                    settings.toggleState = $(settings.el.wrap).attr('data-state') == 'map' ? 'list' : 'map';
                    $(settings.el.wrap).attr('data-state', settings.toggleState);

                    $('html,body').animate({scrollTop: $(settings.el.mapSearchSection).offset().top}, 300);

                    // init events
                    fn.fitBoundsToMarkers();
                    fn.resetListResults();
                },

                loadMore: function () {
                    var newResults = settings.showing + 2;

                    for (settings.showing; settings.showing < newResults; settings.showing++) {
                        if (settings.showing >= $(settings.el.resultsList).children().length - 1) $(settings.el.showMoreBtn).attr('data-visibility', 'hidden');
                        if ($(settings.el.resultsList).children().eq(settings.showing).hasClass('state')) newResults = newResults + 1;
                        $(settings.el.resultsList).children().eq(settings.showing).attr('data-loaded', 'loaded');
                    }

                    fn.updateFixedMenu();

                    // increment the current start
                    settings.currentStart = settings.currentStart + settings.currentResultSize;
                    fn.renderListView()

                },

                // Resets the mobile listings
                resetListResults: function () {
                    $(settings.el.resultsList).children().removeAttr('data-loaded');
                    $(settings.el.showMoreBtn).attr('data-visibility', 'visible');
                    settings.showing = 0;
                    fn.loadMore();
                },

                eventFilterState: function (e) {
                    fn.filterState($(e.currentTarget)[0].value);
                },

                filterState: function (filter) {
                    if (settings.removeSearchString === true) {
                        $(settings.el.address).val('');
                    }

                    // show all markers and return
                    if (filter === 'ALL') {
                        $.each(settings.locations.markers, function () {
                            this.setMap(settings.map.view);
                            this.setVisible(true);
                        });
                    } else {
                        // show filtered markers
                        $.each(settings.locations.markers, function () {
                            if (filter !== this.data.Group) {
                                this.setMap(null);
                                this.setVisible(false);
                            } else {
                                this.setMap(settings.map.view);
                                this.setVisible(true);
                            }
                        });
                    }

                    fn.fitBoundsToMarkers();
                    fn.on.updateResults();
                },

                initListeners: function () {
                    /**
                     * @function initListeners
                     * Initialises listeners on for Google Maps
                     */

                    // Google Maps Events
                    google.maps.event.addListener(settings.map.view, 'click', fn.on.mapClick);
                    google.maps.event.addListener(settings.map.view, 'zoom_changed', fn.checkZoom);
                    google.maps.event.addListener(settings.map.view, 'idle', fn.on.updateResults);
                    google.maps.event.addDomListener(window, 'resize', function () {
                        settings.map.view.setCenter(settings.center);
                    });
                },

                checkBounds: function () {
                    /**
                     * @function checkBounds
                     * Checks the bounds of the map and prevents the user from leaving the country
                     */
                    if (settings.map.view.getZoom() < settings.map.minZoom) {
                        settings.map.view.setZoom(settings.map.minZoom);
                    }

                    if (!settings.map.countryBounds.contains(settings.map.view.getCenter())) {

                        // It`s not OK, find the nearest allowed point and move there
                        var center = settings.map.view.getCenter(),
                            x = center.lng(),
                            y = center.lat(),
                            maxX = settings.map.countryBounds.getNorthEast().lng(),
                            maxY = settings.map.countryBounds.getNorthEast().lat(),
                            minX = settings.map.countryBounds.getSouthWest().lng(),
                            minY = settings.map.countryBounds.getSouthWest().lat();

                        if (x < minX) x = minX;
                        if (x > maxX) x = maxX;
                        if (y < minY) y = minY;
                        if (y > maxY) y = maxY;

                        settings.map.view.setCenter(new google.maps.LatLng(y, x));

                    }

                },
                /**
                 * @function checkZoom
                 * Checks the bounds of the map and prevents the user from leaving the country
                 */
                checkZoom: function () {
                    if (settings.map.view.getZoom() < settings.map.minZoom) {
                        settings.map.view.setZoom(settings.map.minZoom);
                    }
                    fn.showMarkersInCurrentBounds();
                },

                getLocations: function (callback) {

                    /**
                     * @function getLocations
                     * @param callback (Function)
                     * Gets locations from the API
                     */
                    fn.setLoadingState(true);

                    // Ajax request to get stores
                    $.ajax({
                        type: "GET",
                        url: settings.stores,
                        success: function (response) {
                            settings.storeData = settings.allStoreData = response;
                            fn.on.load.all(settings.storeData);
                        },
                        error: function () {
                            debugLog("Error loading map JSON");
                        }
                    });


                },

                buildMarkers: function (options) {
                    /**
                     * @function buildMarkers
                     * @param options (Object)
                     * Loops through the model and creates markers
                     */

                    // Remove all markers
                    settings.locations.markers = [];

                    // Using actual marker objects
                    if (typeof options.markers !== "undefined") {
                        settings.locations.markers = options.markers;
                    }

                    // Using locations from the API
                    else if (typeof options.locations !== "undefined") {

                        if (!options.locations.length) return;

                        var imageMarker = {
                            url: settings.map.defaultCustomMarkerImage,
                            scaledSize: new google.maps.Size(30, 35)
                        };

                        _.each(options.locations, function (location) {

                            // If there is a lat/long for this location
                            if (parseFloat(location.Latitude) && parseFloat(location.Longitude)) {

                                // Add the marker to the map
                                fn.addMarker({
                                    model: 'locations',
                                    position: {
                                        lat: parseFloat(location.Latitude),
                                        lng: parseFloat(location.Longitude),
                                    },
                                    icon: imageMarker,
                                    data: location,
                                    labelClass: "boomtown",
                                    optimized: false
                                });
                            }

                        }, this);
                    }

                    // Hide loading state
                    $(settings.el.loading).removeClass('is-visible');

                    if (!settings.locations.markers.length) return;

                    // Fit the map to the bounds of the markers
                    fn.fitBoundsToMarkers();

                },

                showMarkersInCurrentBounds: function() {
                    if($(settings.el.filter).val() === 'ALL') {
                        var currentBounds = settings.map.view.getBounds();
                        $.each(settings.locations.markers, function() {
                            if(currentBounds.contains(this.getPosition())) {
                                this.setMap(settings.map.view);
                                this.setVisible(true);
                            }
                            else {
                                this.setMap(null);
                                this.setVisible(false);
                            }
                        });
                    }
                },

                addMarker: function (options) {
                    /**
                     * @function addMarker
                     * Adds a marker to the googleMap
                     */
                    if (!options) return;

                    var marker = new google.maps.Marker(_.extend({
                        map: settings.map.view,
                    }, options));


                    // Save the marker
                    settings.locations.markers.push(marker);

                    // Marker events
                    google.maps.event.addListener(marker, 'click', fn.on.markerClick);

                },

                fitBoundsToMarkers: function () {
                    /**
                     * @function fitBoundsToMarkers
                     * Resizes the bounds to fit all visible markers
                     */
                    var bounds = new google.maps.LatLngBounds();

                    // Loop through all markers
                    _.each(settings.locations.markers, function (marker) {
                        // Extend the bounds to the markers bounds
                        if (marker.getVisible()) bounds.extend(marker.getPosition());
                    });

                    // Fit the map to the bounds
                    var map_center = bounds.getCenter();
                    settings.map.view.setCenter(map_center);
                    settings.map.view.panToBounds(bounds);
                    settings.map.view.fitBounds(bounds);

                },
                /**
                 * @function getClosestMarker
                 * @param lat (Number)
                 * @param lng (Number)
                 * Finds the closest marker to lat lng
                 */
                getClosestMarker: function (lat, lng) {
                    var distance = false,
                        closest = -1;

                    _.each(settings.locations.markers, function (marker, i) {

                        var dlat = Math.abs(marker.position.lat() - lat),
                            dlng = Math.abs(marker.position.lng() - lng),
                            d = Math.sqrt(Math.pow(dlat, 2) + Math.pow(dlng, 2));

                        if (distance && d >= distance) return;

                        distance = d;
                        closest = i;

                    }, this);

                    // Set the map center to the closest marker
                    return settings.locations.markers[closest];
                },
                /**
                 * @function findClosestMarker
                 * @param lat (Number)
                 * @param lng (Number)
                 * Finds the closest marker to lat lng
                 */
                findClosestMarker: function (lat, lng) {

                    var closestMarker = fn.getClosestMarker(lat, lng);

                    var bounds = new google.maps.LatLngBounds();
                    var location = new google.maps.LatLng(lat, lng);

                    bounds.extend(location);
                    bounds.extend(closestMarker.getPosition());

                    settings.map.view.fitBounds(bounds);
                },

                setLoadingState: function (state) {

                    var results = $(settings.el.results);

                    if (state == true) {
                        results.addClass('loading');
                    } else {
                        results.removeClass('loading');
                    }
                },

                showInfoWindow: function (markerOrID) {
                    /**
                     * @function showInfoWindow
                     * @param markerOrID (Object or Number)
                     */
                    var marker = _.isObject(markerOrID) ? markerOrID : _.find(settings.locations.markers, function (marker) {
                        return marker.data.Id == markerOrID;
                    });

                    var id = marker.data.Id;

                    if (settings.map.view.getZoom() < settings.map.maxZoom) {
                        settings.map.view.setZoom(settings.map.maxZoom);
                    }

                    settings.map.view.setCenter(marker.getPosition());

                    // Close the info window
                    settings.map.infoWindow.close();

                    var markup = marker.data.template || settings.templates.loadingTemplate;

                    settings.map.infoWindow.setContent(markup);
                    settings.map.infoWindow.open(settings.map.view, marker);
                },

                selectCountry: function (e) {
                    var countryCode = $(e.currentTarget).val();
                    settings.country = countryCode;
                },

                initAutoComplete: function () {
                    var searchInput = document.getElementById(settings.el.searchField);

                    // Create the autocomplete object, restricting the search to geographical location types.
                    autocomplete = new google.maps.places.Autocomplete(
                        (searchInput),
                        {
                            types: ['geocode'],
                            componentRestrictions: {country: settings.countryRestrictions}
                        });
                },

                on: {

                    geo: function (location) {
                        var locale = location.city + ", " + location.country_name;

                        if (settings.ready) fn.fitToAddress(locale, function (response, status) {
                            if (status !== "OK") return;

                            settings.map.view.setZoom(12);
                        });
                    },

                    load: {

                        all: function (response) {
                            /**
                             * @function all
                             * @param response (Object)
                             */

                            // Update the UI
                            fn.buildMarkers({
                                locations: response
                            });

                            fn.initAutoComplete();

                            // Get all existing groups (ie NSW, QLD, Overseas)
                            var groups = [];
                            _.each(response, function (location) {
                                if ($.inArray(location.Group, groups) == -1) groups.push(location.Group);
                            });

                            // Create filter using existing groups
                            _.each(groups, function (group) {
                                $(settings.el.filter).append('<option value="' + group + '">' + group + '</option>')
                            });

                        },

                        list: function (response) {
                            settings.currentStart = 0;
                            settings.viewList = [];
                            /**
                             * @function list
                             * @param response (Object)
                             */
                            response = response || [];

                            settings.currentLocations = [];

                            _.each(response, function (location) {
                                location.fullAddress = location.StreetAddress.split(",").concat(" " + location.Suburb + " " + location.Postcode);

                                // Render the template
                                location.template = settings.templates.infoWindowTemplate(location);

                                var marker = _.chain(settings.locations.markers).find(function (marker) {
                                    return marker.data.Id == location.Id;
                                }).value();

                                if (marker) {
                                    _.extend(marker.data, location);
                                    // Save the marker
                                    settings.currentLocations.push(marker.data);
                                }

                            }, this);

                            var marker = settings.map.infoWindow.anchor,
                                zoomed = settings.map.view.getZoom() >= 9;

                            // check if info window has a marker, and using loading template
                            if (marker && settings.map.infoWindow.content === settings.templates.loadingTemplate) {
                                fn.showInfoWindow(marker.data.Id);
                            }

                            // Don't show results below a certain zoom level
                            settings.currentLocations = _.sortBy(settings.currentLocations, 'Group');

                            // Set DOM data attributes
                            $(settings.el.results).attr('data-results', settings.currentLocations.length).attr('data-zoomed', zoomed);


                            fn.renderListView();
                        }
                    },

                    markerClick: function (e, marker) {
                        /**
                         * @function markerClick
                         * @param e (Event)
                         * @param marker (Object)
                         */

                        fn.showInfoWindow(this);

                    },

                    updateResults: function () {
                        /**
                         * @function clusterinEnd
                         * Fired when the map has finished clustering markers
                         */

                        fn.calculateCenter();

                        fn.showMarkersInCurrentBounds();

                        // Get IDs of visible (unloaded) markers
                        var ids = _.reduce(settings.locations.markers, function (list, marker) {
                            var visible = marker.getMap() && settings.map.view.getBounds().contains(marker.getPosition()),
                                loaded = !!marker.data.RetailerName;

                            return visible ? list.concat(marker.data.Id) : list;
                        }, [], this);

                        // No results
                        if (!ids.length) {
                            $(settings.el.resultsList).html('').removeClass('is-visible');

                            return fn.on.load.list(false);
                        }

                        settings.storeData = _.filter(settings.allStoreData, function (store) {
                            var match = false;
                            _.each(ids, function (id) {
                                if (store.Id == id) match = true;
                            });
                            return match;
                        });

                        fn.on.load.list(settings.storeData);
                        fn.setLoadingState(false);
                    },

                    mapClick: function () {
                        /**
                         * @function mapClick
                         * Fired when the google map is clicked
                         */
                        settings.map.infoWindow.close();
                    },

                    addressSubmit: function (e) {
                        /**
                         * @function postcodeSearch
                         * @param e (Event)
                         * Reverse geocodes the address
                         */
                        e.preventDefault();

                        var address = $(settings.el.address).val();

                        if (!address) {
                            fn.filterState('ALL');
                            return fn.fitBoundsToMarkers();
                        }
                        $(settings.el.filter).val('ALL').change();

                        $(settings.el.loading).addClass('is-visible');
                        fn.fitToAddress(address, function (results, status) {

                            $(settings.el.loading).removeClass('is-visible');
                            $(settings.el.resultsScroll).scrollTop(0);

                            if (status !== "OK") return;

                            var lat = results[0].geometry.location.lat();
                            var lng = results[0].geometry.location.lng();
                            var closestMarker = fn.getClosestMarker(lat, lng);
                            var currentPosition = new google.maps.LatLng(lat, lng);


                            var radius = google.maps.geometry.spherical.computeDistanceBetween(closestMarker.getPosition(), currentPosition);

                            // We create a circle to look within:
                            var searchArea = {
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                center : currentPosition,
                                //map: settings.map.view,
                                radius : radius + (radius * 0.2)// Radius + 20% of Radius
                            };
                            var circle = new google.maps.Circle(searchArea);

                            $.each(settings.locations.markers, function() {
                                this.setMap(settings.map.view);
                                this.setVisible(false);
                            });
                            $.each(settings.locations.markers, function() {
                                if (google.maps.geometry.spherical.computeDistanceBetween(this.getPosition(), circle.getCenter()) <= circle.getRadius()) {
                                    this.setMap(settings.map.view);
                                    this.setVisible(true);
                                }
                            });
                            settings.map.view.fitBounds(circle.getBounds());

                        }.bind(this));

                        // var self = this;
                        /*fn.filterState('ALL', function() {
                            settings.removeSearchString = true;
fn.filterState('ALL'

                        });*/
                    },

                    addressKeyup: function () {
                        /**
                         * @function addressKeyup
                         * Key up even for the address field
                         */
                        if ($(settings.el.address).val()) $(settings.el.address).removeClass('is-invalid');
                    }
                },

                renderListView: function () {
                    /*
                    Start point should always be 0, as splice method is trimming first 10 (settings.currentResultSize)
                    from settings.currentLocations array anyway, reducing it by 10
                     */

                    var sliced = settings.currentLocations.splice(0, settings.currentResultSize);

                    for (var item in sliced) {
                        if (sliced.hasOwnProperty(item)) {
                            settings.viewList.push(sliced[item]);
                        }
                    }
                    var markup = settings.templates.listTemplate({locations: settings.viewList, currentstate: ''});
                    $(settings.el.resultsList).html(markup);
                },

                fitToAddress: function (address, callback) {

                    // Set the map bounds to the country restrictions
                    settings.map.geocoder.geocode({
                        address: address
                    }, function (results, status) {
                        // zoom out to country if no results found
                        if (status !== "OK") {
                            return fn.fitToAddress('', callback);
                        }

                        if (results[0].address_components[0].short_name === settings.country) {
                            status = "DEFAULT";
                            settings.map.view.setZoom(5);
                        }

                        // Fit the map to the bounds
                        if (_.isUndefined(results[0].place_id) || settings.map.placeID !== results[0].place_id) {
                            settings.map.placeID = results[0].place_id;
                        }
                        if (typeof callback === "function") callback.apply(this, arguments);

                    }.bind(this));
                },

                /**
                 * @function showLocation
                 * @param lat (number)
                 * @param lng (number)
                 * Focus selected location on map, at provided lat + long coords
                 */
                showLocation: function (lat, lng, id) {
                    settings.map.view.setCenter(new google.maps.LatLng(lat, lng));

                    fn.showInfoWindow(id);

                    if (settings.map.view.getZoom() < settings.map.maxZoom) {
                        settings.map.view.setZoom(settings.map.maxZoom);
                    }
                }
            },
            Map = {
                init: function () {
                    if (!$(settings.el.map).length) return;
                    window._gmapsReady = fn.ready;

                    $.getScript(window.mapsApiEndpoint + "?v=3.39&key=AIzaSyDhzOpedxkbG1wavPCvJPKqrlEMRXK4WXg&callback=_gmapsReady&region=AU&language=en-au&libraries=geometry,places");
                }
            };

        /**********************************
         // Simple constructor
         **********************************/
        function __construct() {
            Map.init();
        }

        __construct();


        //
        // Make some events accessible from global scope
        //
        return {};
    };


})(jQuery, jQuery);
