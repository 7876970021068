var Bio = Bio || {};

(function($, jQuery) {
  'use strict';

  Bio.Forms = function( options ) {

    var settings = {

      // Elements
      el: {
        form: 'form',
        fileInput: 'input[type="file"]',
        submitInput: 'input[type="submit"]'
      },

    },

    Forms = {
      init: function () {
        $('select').select2({
            minimumResultsForSearch: 15,
            width: '100%',
            theme: "custom"
        });

        $('.select2-selection__arrow').append('<svg class="icon icon--down"><use xlink:href="/static/svg/sprite.svg#arrow-down"></use></svg><svg class="icon icon--up"><use xlink:href="/static/svg/sprite.svg#arrow-up"></use></svg>');

        Forms.constructSubmit();
        Forms.constructFileInput();

        // $(settings.el.form).on('submit', Forms.submit)
        // .on('focusin', '.invalid, .invalid [tabindex]', Forms.focus);

      },

      constructSubmit: function () {
        $(settings.el.submitInput)
        .wrap('<label class="btn-primary-alt"></div>')
        .addClass('sr-only')
        .after('<span class="copy">Submit</span><svg class="icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/svg/sprite.svg#arrow-right"></use></svg>');
      },

      constructFileInput: function () {
        $(settings.el.fileInput).each(function () {
          $(this).after('<label for="' + $(this).attr('id') + '"><div class="btn">Choose image</div> <span></span></label>');

          var label  = this.nextElementSibling,
            labelVal = label.innerHTML;

          this.addEventListener( 'change', function(e) {  
            var fileName = '';

            if( this.files && this.files.length > 1 ) fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else fileName = e.target.value.split( '\\' ).pop();

            if (fileName) label.querySelector('span').innerHTML = fileName;
            else label.innerHTML = labelVal;
          });

          // Firefox bug fix
          this.addEventListener( 'focus', function(){ this.classList.add( 'has-focus' ); });
          this.addEventListener( 'blur', function(){ this.classList.remove( 'has-focus' ); });

        });
      },

      submit: function (e) {

        // var required = $(settings.el.form).find("[data-val-required]").not("[type='submit']");

        // // if invalid scroll to top of form
        // if(!Validate.fields(required)) {
        //   e.preventDefault();
        //   return $(window).scrollTop($(settings.el.form).offset().top);
        // }
        
      },

      focus: function (e) {
        // $(e.target).removeClass('invalid').closest('.contourField.invalid').removeClass('invalid').find('.invalid').removeClass('invalid');
      }

    },

    Validate = {

      // fields: function ($fields) {

      //   return $fields.each(function () {         
      //     var $input = $(this),
      //     regex = $input.attr('data-regex'),
      //     isDate = $input.attr('type') === "date",
      //     value = this.type==="checkbox" ? this.checked : this.value,
      //     valid = !!value;

      //     // if input has custom regex
      //     if(regex) {
      //       regex = new RegExp(regex);
      //       if (!regex.test(value)) valid = false;
      //     }

      //     // check file type
      //     if(this.type==='file') {
      //       var acceptedFiles = $input.attr('accept').replace(/ /g,'').split(','),
      //           ext = value.substr((value.lastIndexOf('.'))).toLowerCase();

      //       if($.inArray(ext, acceptedFiles) == -1) valid = false;
      //     }

      //     // toggle invalid on element and parent
      //     $input.toggleClass('invalid', !valid)
      //     .closest('.contourField').toggleClass('invalid', !valid);

      //   }).filter('.invalid').length === 0;
      // },

    };

    /**********************************
    // Simple constructor
    **********************************/
    function __construct() {
      Forms.init();
    }

    __construct();


    //
    // Make some events accessible from global scope
    //
    return {

    };
  };


})(jQuery, jQuery);
