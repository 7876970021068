(function($, w, undefined) {
    function HomeCarousel(args) {
        this.defaults = {
            elem: '.home-carousel',
            swiperContainer: '.swiper-container',
            swiperSlide: '.swiper-slide',
            btnPrev: '.btn-prev',
            btnNext: '.btn-next',
            pagination: '.pagination',
            defaultSpeed: 5000,
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.swiper = null;
        this.slides = [];
        this.activePlyr = 0;

        this.$elem = $(this.options.elem);

        if(!this.$elem.length > 0){

            return;
        }
        this.$swiperContainer = $(this.parent(this.options.swiperContainer));
        this.$slides = $(this.parent(this.options.swiperSlide));

        this.initSwiper();

        var self = this;
        var desktopActive = Bio.Utility.StateManager.ssm.isActive('desktop');
        if(desktopActive){
            this.initDesktopView();
        }

        Bio.Utility.StateManager.addOnEnter('desktop', function() {
            self.initDesktopView();
        });
    };

    HomeCarousel.prototype.parent = function (selector) {
        return $(selector, this.$elem);
    };

    HomeCarousel.prototype.initDesktopView = function (){
        var self = this;
        this.initVideoSlides();
        this.detectPlayback(0);
        this.swiper.on('slideChangeTransitionStart', function () {
            var activeSlide = self.swiper.realIndex;
            self.detectPlayback(activeSlide);

        });

        this.swiper.on('slideChangeTransitionEnd', function () {
            var previousSlide = self.swiper.previousIndex - 1;
            self.detectPause(previousSlide);
        });
    }

    HomeCarousel.prototype.initSwiper = function (){
        this.swiper = new Swiper(this.$swiperContainer[0], {
            loop: true,
            navigation: {
                prevEl: this.options.btnPrev,
                nextEl: this.options.btnNext
            },

            pagination: {
                el: this.options.pagination,
                type: 'fraction',
            },

            autoplay: {
                delay: 5000,
            },

            speed: 750
        });
    };

    HomeCarousel.prototype.initVideoSlides = function (){
        var self = this;
        this.$slides.each(function(index){
            var slide = {
                id: index,
                videoPlyr: null
            };

            if(this.classList.contains('slide-video')){

                var videoElem = $('video', this);
                if (videoElem.length > 0) {
                    var videoUrl = videoElem.attr('data-video-url');
                    var videoType = videoElem.attr('data-video-type');
                    var videoPlaceholder = videoElem.attr('data-video-placeholder');

                    slide.videoPlyr = new Plyr(videoElem.get(0), {
                        hideControls: true,
                        autoplay: false,
                        muted: true,
                        controls: []
                    });


                    slide.videoPlyr.source = {
                        type: 'video',
                        sources: [{
                            src: videoUrl,
                            type: 'video/' + videoType
                        }],
                        poster: videoPlaceholder
                    };
                }
            }
            self.slides.push(slide);
        });
    };

    HomeCarousel.prototype.detectPause = function (previousSlideIndex){
        var previousSlide = this.slides[previousSlideIndex];
        if (previousSlide.videoPlyr !== null){
            previousSlide.videoPlyr.pause();
            previousSlide.videoPlyr.currentTime = 0;
        }
    };

    HomeCarousel.prototype.detectPlayback = function (currentSlideIndex){
        var activeSlide = this.slides[currentSlideIndex];
        if (activeSlide.videoPlyr !== null){
            activeSlide.videoPlyr.play();
        }
    };

    w.Bio= w.Bio|| {};
    w.Bio.Modules = w.Bio.Modules || {};
    w.Bio.Modules.HomeCarousel = HomeCarousel;

})(jQuery, window);
