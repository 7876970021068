var Bio = Bio || {};

(function($, jQuery) {
	'use strict';

	Bio.Cookie = function( options ) {

		var secret = {
		    active: true,
		    delimeter: "!",
		    xor: 6
		  },
		  current = {},
		  update = function (name, value, date) {
		    document.cookie = name + "=" + value + "; expires=" + (date ? date.toGMTString() : 0) + "; path=/";
		  },
		  cookie = {
		    get: function (name, encoded) {
		      // if(_.d(current[name])) return current[name];
		      if(secret.active && encoded!==false) name = cookie.secret.encode(name).replace(/([.^$*+?()[{|])/g, "\\$1");
		      var re = new RegExp("(^| )" + name + "=[^;]+", "i"),
		      match = document.cookie.match(re);
		      return match ? cookie.decode(match[0].split("=")[1]) : undefined;
		    },

		    // set a list of cookies, takes an object of name:value pairs or a single name and value string
		    set: function (list, value, days, issecret, is_hours, is_minutes) {

		      if(typeof issecret === "undefined") issecret = secret.active;

		      // if list is string assume single property
		      if(typeof list === 'string') {

		        // if list only assume remove
		        if(arguments.length<2) return cookie.remove(list);

		        // reassign to {name: value}
		        list = _.object([list], [value]);

		      }else{
		        days = value;
		        issecret = days;
		      }

		      // extend current list of values
		      _.extend(current, list);

		      var expires = new Date();

		      if (days === false) expires = 0;
		      else if(!is_minutes && !is_hours) expires.setDate(expires.getDate() + parseInt(days || 365));
		      else if(!is_hours) expires.setMinutes(expires.getMinutes() + parseInt(days));
		      else expires.setHours(expires.getHours() + parseInt(days));

		      _.each(list, function (val, name) {
		        if(issecret) name = cookie.secret.encode(name);
		        update(name, cookie.encode(val, issecret), expires);
		      });
		    },

		    // remove a list of cookies
		    remove: function (names) {
		      _.each(typeof names === "string" ? arguments : names, function (name) {
		        delete current[name];
		        if(secret.active) name = cookie.secret.encode(name);
		        update(name, '', new Date(0));
		      });
		    },
		    encode: function (value, secret) {
		      value = JSON.stringify(value);
		      if(secret) value = cookie.secret.encode(value);
		      return encodeURIComponent(value);
		    },
		    decode: function (value) {
		      try {
		        return $.parseJSON(cookie.secret.decode(decodeURIComponent(value)));
		      } catch (e) {
		        return value;
		      }
		    },

		    secret: {
		      encode: function (value) {
		        return secret.delimeter + cookie.secret.xor(value) + secret.delimeter;
		      },
		      decode: function (value) {
		        if(!RegExp("^" + secret.delimeter + ".*" + secret.delimeter + "$").test(value)) return value;
		        return cookie.secret.xor(value.slice(secret.delimeter.length,-secret.delimeter.length));
		      },
		      xor: function (value) {
		        return _.reduce(value.split(""), function (result, val) {
		          return result + String.fromCharCode(secret.xor^val.charCodeAt(0));
		        }, "");
		      }
		    }
		  };

		  // set alias
		  cookie.unset = cookie.remove;
		  cookie.save = cookie.update = cookie.set;
		  cookie.value = cookie.get;


		/**********************************
		// Simple constructor
		**********************************/
		// function __construct() {

		// }

		// __construct();


		//
		// Make some events accessible from global scope
		//
	  	return cookie;
	};


})(jQuery, jQuery);
