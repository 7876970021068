var Bio = Bio || {};

(function($, jQuery) {
	'use strict';

	Bio.Accordion = function( options ) {

		var settings = {

			// Elements
			el: {
				accordion: ".accordion",
				toggle: ".btn-accordion-trigger",
				content: ".accordion-content",
				datatext: '.read-more span',
			},
			speed: 300,
			active: false

		},

		Accordion = {
			init: function () {
				if(!$(settings.el.accordion).length) return;
        		$(settings.el.toggle).on('click', Accordion.toggle);

				$(settings.el.content).css('display', 'none');

			},

			toggle: function (e) {

				var $el = $(e.currentTarget),
				$next = $el.next();

				$(settings.el.datatext).text($(settings.el.datatext).data('textopen'));

				// Toggle data state on the accordion toggle
				if ( $el.attr('data-state') === 'active' ) {
					$el.attr('data-state', '');
					$el.find(settings.el.datatext).text($(settings.el.datatext).data('textopen'));  // grab data attribute contents

				} else {
					$(settings.el.toggle).attr('data-state', '');
					$el.attr('data-state', 'active');
					$el.find(settings.el.datatext).text($(settings.el.datatext).data('textclose'));  // grab data attribute contents
				}

				// Expand or collapse this accordion content
				$next.slideToggle(settings.speed);

				// Hide the other panels
				$(settings.el.content).not($next).slideUp(settings.speed);

				// set currently opened container (used in other modules)
				settings.active = $next;

			}
			
		};

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {
			Accordion.init();
		}

		__construct();


		//
		// Make some events accessible from global scope
		//
		return {

		};
	};


})(jQuery, jQuery);
