var Bio = Bio || {};

(function ($, jQuery) {
	'use strict';

	Bio.MenuMobile = function (options) {

		var defaults = {

			selectors: {
				body: 'body',
				mobileMenu: '.mobile-menu',
				toggle: '.navbar-menu-toggle',
				openSubNav: '.btn-open-subnav',
				closeSubNav: '.btn-close-subnav',
				pageBranch: '.navigation-page-branch'
			},

			classes: {
				toggleActive: 'is-active',
				mobileMenu: 'mobile-menu',
				menuVisible_body: 'mobile-menu-visible',
				menuVisible_menu: 'visible'
			}
		};

		options = $.extend(true, {}, defaults, options);

		/**********************************
		// Declare variables
		**********************************/
		var $body = $(options.selectors.body);
		var $mobileMenu = $(options.selectors.mobileMenu);
		var $toggle = $(options.selectors.toggle);
		var $openSubNav = $(options.selectors.openSubNav);
		var $closeSubNav = $(options.selectors.closeSubNav);
		var $pageBranch = $(options.selectors.pageBranch);

		var active = false;

		/**********************************
		// Interface Events
		**********************************/
		function toggleMenu() {
			if(!active) {
				return;
			}
			if ($body.hasClass(options.classes.menuVisible_body)) {
				closeMenu();
			} else {
				openMenu();
			}
		}

		function openMenu() {
			$mobileMenu.addClass(options.classes.menuVisible_menu);
			$toggle.addClass(options.classes.toggleActive);
			// $body.animate({
			// 	scrollTop: 0
			// }, 200);

			// Don't immediately toggle the class - first wait a millisecond so that the
			// overlay has a millisecond to first set display: block. This is because
			// CSS does not allow trasitioning from an element with display: none set
			setTimeout(function () {
				$body.addClass(options.classes.menuVisible_body);
			});
		}

		function closeMenu() {
			$body.removeClass(options.classes.menuVisible_body);
			$toggle.removeClass(options.classes.toggleActive);
			$pageBranch.removeClass(options.classes.toggleActive);
			// Don't immediately set display none - first wait until the opacity transition
			// has finished. The amount of time for the timeout should match the transition
			// duration in modules/_site-overlay.scss
			setTimeout(function () {
				$mobileMenu.removeClass(options.classes.menuVisible_menu);
			}, 200);
		}

		function openSubNav(pageBranch) {
			pageBranch.addClass(options.classes.toggleActive);
		}

		function closeSubNav(pageBranch) {
			pageBranch.removeClass(options.classes.toggleActive);
		}


		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents() {
			window.Bio.Utility.StateManager.addOnEnter('mobile', onBPEnter);
			window.Bio.Utility.StateManager.addOnLeave( 'mobile', onBPLeave);

			$toggle.click(function (event) {
				toggleMenu();
			});

			$openSubNav.click(onOpenSubNav);
			$closeSubNav.click(onCloseSubNav);
		}

		function onBPEnter() {
			active = true;
			$body.addClass(options.classes.mobileMenu);
		}

		function onBPLeave() {
			active = false;
			$body.removeClass(options.classes.mobileMenu);
			closeMenu();
		}

		function onOpenSubNav(event) {
			event.preventDefault();
			event.stopPropagation();

			var pageBranch = $(event.target).parents(options.selectors.pageBranch);
			openSubNav(pageBranch);
		}

		function onCloseSubNav(event) {
			event.preventDefault();
			event.stopPropagation();

			var pageBranch = $(event.target).parents(options.selectors.pageBranch);
			closeSubNav(pageBranch);
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init
			bindEvents();

		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
			//'sampleEvent': sampleEvent,
		};
	};


})(jQuery, jQuery);
