(function($, w, undefined) {
    function NestedVideo(args) {
        this.defaults = {
            elem: '.nested-video',
            playerEmbed: '.player-embed',
            imgLayer: '.img-layer',
            btnPlay: '.btn-play'
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.videoPlayer = null;

        this.$elem = $(this.options.elem);

        if(!this.$elem.length > 0){
            return;
        }

        this.$playerEmbed = $(this.parent(this.options.playerEmbed));
        this.$imgLayer = $(this.parent(this.options.imgLayer));
        this.$btnPlay = $(this.parent(this.options.btnPlay));

        this.initVideo();
        this.bindEvents();
    }

    NestedVideo.prototype.parent = function (selector) {
        return $(selector, this.$elem);
    };

    NestedVideo.prototype.initVideo = function (){

        this.videoPlayer = new Plyr(this.$playerEmbed, {
            autoplay: this.options.autoplay,
        });
    };

    NestedVideo.prototype.hideImgLayer = function (){
        this.$imgLayer.addClass('video-active');
    };

    NestedVideo.prototype.playVideo = function() {
        this.videoPlayer.play();
        this.hideImgLayer();
    };


    NestedVideo.prototype.bindEvents = function(){
        var self = this;
        if(this.$btnPlay){
            this.$btnPlay.click(function (event) {
                self.playVideo();
            });
        }
    };


    w.Bio= w.Bio|| {};
    w.Bio.Modules = w.Bio.Modules || {};
    w.Bio.Modules.NestedVideo = NestedVideo;

})(jQuery, window);
