var Bio = Bio || {};

$(function () {
    /**********************************
    COMPONENT + MODULE INIT CALLS
    **********************************/
    Bio.Map();
    Bio.Accordion();

    // Initialise the Mobile Menu
    new Bio.MenuMobile();
    new Bio.MenuDesktop();

    //Notification Banner
    new Bio.Modules.NotificationBanner();

    /*
    Refresh 2020
     */
    Bio.Utility.StateManager.run();
    new Bio.Modules.ProductScrollAnchors();

    /*
    Global Tooltip init
     */
    tippy('[data-tippy-content]', {
        trigger: 'click'
    });
});
