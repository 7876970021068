(function($, w, undefined) {
    function BlogArticleListing(args) {
        this.defaults = {
            elem: '.blog-article-listing',
            articleFilters: '.article-filter-filter',
            articleContainer: '.blog-article-listing .articles .row.row-flush',
            obj: {}
        }

        this.options = $.extend(true, {}, this.defaults, args)
        this.$elem = $(this.options.elem)
        this.$articleFilters = $(this.options.articleFilters)
        this.$articleContainer = $(this.options.articleContainer)
        this.$obj = $(this.options.obj)

        if (!this.$elem.length > 0) {
            return
        }
        
        this.onClickArticleFilters()
    }
    
    BlogArticleListing.prototype.onClickArticleFilters = function() {
        var self = this
        var contentTags = document.querySelectorAll('.content-tags li a')

        contentTags.forEach(tag => {
            tag.addEventListener('click',  function(event) {
                event.preventDefault()

                var url = tag.getAttribute('href')
                window.history.pushState({ path: url }, '', url)

                var clickedCategoryUrl = tag.getAttribute('data-url')
                self.updateActiveStates()
                self.fetchArticles()
            })
        })
    }

    BlogArticleListing.prototype.updateActiveStates = function() {
        var contentTags = document.querySelectorAll('.content-tags li')
        var currentPath = window.location.pathname + window.location.search
        
        contentTags.forEach(tag => {
            var categoryUrlSegment = tag.querySelector('a').getAttribute('data-url')
            var isActive = categoryUrlSegment.includes(currentPath)

            if (isActive) {
                tag.classList.add('active')
            } else {
                tag.classList.remove('active')
            }
        })
    }

    BlogArticleListing.prototype.fetchArticles = function () {
        var currentPath = window.location.pathname + window.location.search
        var apiUrl = '/Umbraco/Api/Articles/GetArticles?urlPath=' + currentPath

        $.ajax({
            url: apiUrl,
            method: 'GET',
            dataType: 'json',
            success: function (res) {
                this.$obj = res

                this.updateArticlesContainer() // update the articles container after fetching new data
                this.updatePaginationContainer() // update the pagination container after fetching new data
            }.bind(this),
            error: function (err) {
                console.error('error:', err)
            }
        })
    }

    BlogArticleListing.prototype.updatePaginationContainer = function() {
        var paginationContainer = document.querySelector('#paginationContainer')
        
        paginationContainer.innerHTML = ''
        
        // if (response.items.length > 0) {
        if (this.$obj) {
            var obj = this.$obj
            var paginationHtml = '<div class="pagination javascript">'
            paginationHtml += '<div class="pages">'

            // previous page link
            var previousLinkCssClass = obj.hasPreviousPage ? '' : 'inactive'
            var previousPaginationUrl = this.getArticleLandingUrl(obj.urlPath, obj.articleFilter, obj.pageNumber - 1)
            paginationHtml += '<a href="' + previousPaginationUrl + '" class="nav nav-prev ' + previousLinkCssClass + '" rel="prev">'
            paginationHtml += '<svg class="icon"><use xlink:href="/static/svg/sprite.svg#arrow-wide-left"></use></svg>'
            paginationHtml += '<p class="text">Previous</p>'
            paginationHtml += '</a>'

            // page Numbers
            paginationHtml += '<ul class="page-numbers">'
            var totalPagesToShow = 3
            var startPage = Math.max(1, obj.pageNumber - 1)
            var endPage = Math.min(startPage + totalPagesToShow - 1, obj.pageCount)

            if (obj.pageNumber >= 2) {
                paginationHtml += '<li class="ellipsis"><span>...</span></li>'
            }

            for (var i = startPage; i <= endPage; i++) {
                let paginationUrl = this.getArticleLandingUrl(obj.urlPath, obj.articleFilter, i)
                if (obj.pageNumber === i) {
                    paginationHtml += '<a href="' + paginationUrl + '"><li class="active">' + i + '</li></a>'
                } else {
                    paginationHtml += '<a href="' + paginationUrl + '"><li>' + i + '</li></a>'
                }
            }

            if (endPage < obj.pageCount) {
                paginationHtml += '<li class="ellipsis"><span>...</span></li>'
            }

            paginationHtml += '</ul>'

            // next page link
            var nextLinkCssClass = obj.hasNextPage ? '' : 'inactive'
            var nextPaginationUrl = this.getArticleLandingUrl(obj.urlPath, obj.articleFilter, obj.pageNumber + 1)
            paginationHtml += '<a href="' + nextPaginationUrl + '" class="nav nav-prev ' + nextLinkCssClass + '" rel="prev">'
            paginationHtml += '<p class="text">Next</p>'
            paginationHtml += '<svg class="icon"><use xlink:href="/static/svg/sprite.svg#arrow-wide-right"></use></svg>'
            paginationHtml += '</a>'

            paginationHtml += '</div>' // close .pages div from above
            paginationHtml += '</div>' // close .pagination div from above
            
            paginationContainer.innerHTML = paginationHtml

            // Attach click event listener to pagination links
            paginationContainer.querySelectorAll('.pagination .pages a').forEach(function(link) {
                link.addEventListener('click', function(event) {
                    event.preventDefault()
                    var url = link.getAttribute('href')
                    
                    window.history.pushState({ path: url }, '', url)
                    this.fetchArticles()
                    this.scrollToFilters()
                }.bind(this))
            }.bind(this))
        } else {
            paginationContainer.innerHTML = '' // error handling, don't render anything
        }
    }
    
    BlogArticleListing.prototype.scrollToFilters = function() {
        this.$articleFilters.get(0).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }
    
    BlogArticleListing.prototype.getArticleLandingUrl = function(urlPath, articleFilter, pageNumber) {
        return urlPath + '&pageNumber=' + pageNumber
    }

    BlogArticleListing.prototype.updateArticlesContainer = function() {
        var self = this
        var articlesContainer = this.$elem
        var articlesHtml = ''
        var items = this.$obj.items
        
        articlesContainer.empty() // clear container

        if (items.length === 0) {
            articlesContainer.append(`
                <div class="article-tile no-articles">
                    <p>No articles found.</p>
                </div>
            `)
            return
        }

        // loop through items and generate HTML
        for (var i = 0; i < items.length; i++) {
            var article = items[i]
            var categoryNames = article.categoryNames || ''

            // ATTENTION: Any rendering changes required here will probably also need to be made in src\BioIsland.Web\Views\Partials\Blogs\_BlogArticleListing.cshtml
            var articleHtml = `
                <div class="col-12 col-lg-4">
                    <a href="${article.url}" class="btn-arrow-wide">
                        <div class="article-tile">
                            <div class="img-block">
                                <img src="${article.thumbnailImageUrl}" loading="lazy" alt="${article.thumbnailImageAltText}" />
                            </div>
                            <div class="content-block">
                                ${categoryNames ? `<p class="article-tags">${categoryNames}</p>` : ''}
                                <p class="article-title">${article.name}</p>
                                <p class="article-desc">${article.thumbnailDescription}</p>
                                <span>
                                    <span class="txt">${article.thumbnailCtaLinkCaption || 'Read this article'}</span>
                                    <svg class="icon">
                                        <use xlink:href="/static/svg/sprite.svg#arrow-wide-right"></use>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
            `

            articlesHtml += articleHtml
            
            if ((i + 1) % 3 === 0 || i === items.length - 1) {
                var rowHtml = `
                    <div class="articles">
                        <div class="row row-flush">${articlesHtml}</div>
                    </div>
                `
                articlesContainer.append(rowHtml)
                articlesHtml = '' // reset articlesHtml for next load of articles
            }
        }
    }

    w.Bio= w.Bio|| {}
    w.Bio.Modules = w.Bio.Modules || {}
    w.Bio.Modules.BlogArticleListing = BlogArticleListing

})(jQuery, window)
