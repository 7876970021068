var Bio = Bio || {};

(function($, jQuery) {
	'use strict';

	Bio.Tabs = function( options ) {

		var settings = {

			// Elements
			el: {
				wrapper: ".tabs-dynamic.tabs-wrapper",
				tabs: ".tabs",
				tab: ".tabs li",
				tabLink: ".tabs li a",
				tabSelect: ".tabs-xs select",
				listings: ".tabs-content-wrapper > *",
			},

		},

		Tabs = {
			init: function () {
				$(settings.el.wrapper).on('click', settings.el.tabLink, Tabs.toggle);
				$(settings.el.wrapper).on('change', settings.el.tabSelect, Tabs.toggle);

				$(settings.el.tabs).each(Tabs.createSelect);
			},

			createSelect: function () {
				var options = '';

				$(this).find('li').each(function () {
					var value = $(this).attr('data-cat');
					options = options + '<option value="' + value + '">' + $.trim($(this).text()) + '</option>';
				});

				var select = '<div class="custom-select-wrapper tabs-xs" data-pre-text="View: "><select>' + options + '</select></div>';

				$(this).after(select);
                            
			},

			toggle: function (e) {

				e.preventDefault();

				var currentCategory,
					tab;

				// Create variables depending on desktop <li> list has been clicked or mobile <select> view has been changed
				if (e.type == 'change') {
					currentCategory = $(e.currentTarget)[0].value;

					// if mobile view, find active desktop tab to allow smooth resize transition into desktop
					$(e.delegateTarget).find(settings.el.tab).each(function () {
						if ($(this).attr('data-cat') == currentCategory) tab = $(this);
					});
				} else {
          			tab = $(e.currentTarget).parent();
					currentCategory = tab.attr('data-cat');

					// if desktop view, change the select value to allow smooth resize transition into mobile
					$(e.delegateTarget).find(settings.el.tabSelect)[0].value = currentCategory;
				}

				// Update the tab
				$(e.delegateTarget).find(settings.el.tab).removeClass('active');
				tab.addClass('active');

				// Show all
				if (currentCategory === 'all') {
				  return $(e.delegateTarget).find(settings.el.listings).each(function () {
				    $(this).attr('data-state', 'visible');
				  });
				}

				$(e.delegateTarget).find(settings.el.listings).each(function() { 
				  if (!$(this).attr('data-cat')) return $(this).attr('data-state', 'hidden');

				  var categories = $(this).attr('data-cat').split(', '),
				      visible = false;

				  _.each(categories, function(category) {
				    if (currentCategory == category) visible = true;
				  });

				  $(this).attr('data-state', visible ? 'visible' : 'hidden');
				});

			}
		};

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {
			Tabs.init();
		}

		__construct();


		//
		// Make some events accessible from global scope
		//
		return {

		};
	};


})(jQuery, jQuery);
