var Bio = Bio || {};
(function ($, jQuery) {
    'use strict';

    Bio.HomeFeature = function (options) {

        var defaults = {

            selectors: {
                slider: '.feature-carousel'
            },

            classes: {
                menuVisible_body: 'mobile-menu-visible',
                menuVisible_menu: 'visible'
            },

            desktop_options: {

            },

            mobile_options: {

            }
        };

        options = $.extend(true, {}, defaults, options);

		/**********************************
		// Declare variables
		**********************************/
        var $slider = $(options.selectors.slider);

        var featureSlide = 0;
        var featurePaused = false;


        var videos = $('.slide-wrapper', $slider).map(function (index, elem) {
            var videoElem = $('video', elem);
            if (videoElem.length > 0) {
                var videoUrl = videoElem.attr('data-video-url');
                var videoType = videoElem.attr('data-video-type');
                var videoPlaceholder = videoElem.attr('data-video-placeholder');

                var videoPlyr = new Plyr(videoElem.get(0), {
                    hideControls: true,
                    autoplay: false,
                    muted: true,
                    controls: []
                });
                videoPlyr.source = {
                    type: 'video',
                    sources: [{
                        src: videoUrl,
                        type: 'video/'+videoType
                    }],
                    poster: videoPlaceholder
                };
                videoPlyr.on('ended', function (e) { 
                    if (!featurePaused) {
                        $slider.slick('slickNext');
                    }
                    else {
                        e.detail.plyr.play();
                    }
                });
                return videoPlyr;
            }
            else {
                return false;
            }
        });

		/**********************************
		// Interface Events
		**********************************/


		/**********************************
		// Setup Events
		**********************************/

        //General events
        function bindEvents() {
            $slider.slick({
                slide: '.slide-wrapper',
                appendArrows: '.slider-nav',
                nextArrow: '<button type="button" class="slick-next"><svg class="icon"><use xlink:href="/static/svg/sprite.svg#arrow-right"></use></svg></button>',
                prevArrow: '<button type="button" class="slick-prev"><svg class="icon"><use xlink:href="/static/svg/sprite.svg#arrow-left"></use></svg></button>',
                arrows: true,
                autoplay: false,
                autoplaySpeed: 5000,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                }
                ]
            });

            $('.slider-nav button').click(function () {
                $slider.slick('slickPause');
                featurePaused = true;
            });

            $slider.on('afterChange', function (event, slick, direction) {
                if(featureSlide === slick.currentSlide) {
                    return;
                }
                var previousPlyr = videos[featureSlide];
                if (previousPlyr !== false) {
                    previousPlyr.pause();
                    previousPlyr.currentTime = 0;
                }

                var videoPlyr = videos[slick.currentSlide];
                if (videoPlyr !== false) {
                    videoPlyr.play();
                }
                else {
                    if (!featurePaused) {
                        // if the slide is not a video then timeout the autoslide.
                        setTimeout(function () {
                            $slider.slick('slickNext');
                        }, 5000);
                    }
                }

                featureSlide = slick.currentSlide;
            });
        }


		/**********************************
		// Simple constructor
		**********************************/
        function __construct() {

            // Init
            bindEvents();

            // Play the video if the first slide is a video.
            var videoPlyr = videos[0];
            if (typeof videoPlyr !== 'undefined' && videoPlyr !== false) {
                videoPlyr.once('ready', function(e) {
                    e.detail.plyr.once('canplay', function(ecp) {
                        ecp.detail.plyr.play();
                    });
                });
            }

        }

        __construct();

        //
        // Make some events accessible from global scope
        //
        return {
            //'sampleEvent': sampleEvent,
        };
    };


})(jQuery, jQuery);
