var Bio = Bio || {};

(function($, jQuery) {
	'use strict';

	Bio.Social = function( options ) {

		if (!window.FB) var FB = 0;
	  	if (!window.twttr) var twttr = 0;

		  var props = {
		    sharer: true,
		  },

		  fn = {

		    channel: {
		      facebook: 'Facebook',
		      twitter: 'Twitter',
		      mail: "Mail"
		    },

		    urlShare: function (url, channel) {
		      var width = 575,
		      height = 255,
		      options = [
		        'status=1',
		        'width=' + width,
		        'height=' + height,
		        'left=' + (screen.width - width)*0.5,
		        'top=' + (screen.height - height)*0.5
		      ].join(",");

		      if(channel) Social.share(channel, false);
		      window.open(url,'_blank', options);
		    },

		    init: {
		      twitter: function () {
		        $(".twitter").each(function () {
		         var $el = $(this),
		              url = [
		                // Twitter intents URL
		                'https://twitter.com/intent/tweet?',
		                // message
		                'text=' + encodeURIComponent($el.data('text')),
		                // url
		                '&url=' + fn.shareURLencoded
		              ].join("");
		              $el.attr("href", url);
		        });
		      },

		      facebook: function () {
		        fn.sharer = !window.FB || props.sharer;

		        $('.facebook').each(function () {
		          var $el = $(this),
		          url = !fn.sharer ? fn.shareURL : 'https://www.facebook.com/sharer.php?u=' + fn.shareURLencoded;
		          $el.attr("href", url);
		        });
		      },

		      mail: function () {
		        var $el = $('.mail'),
		        url = [
		          // email subject
		          'mailto:?subject=' + encodeURIComponent($el.data('subject')),
		          // email
		          '&body=' + encodeURIComponent($el.data('text'))// + fn.shareURL
		        ].join("");
		        $el.attr("href", url);
		      }
		    }
		  },

		  Social = {

		    // pass in false if share isn't confirmable (eg. on url shares)
		    share: function (channel, confirmed) {
		      // Analytics.track.event('social', 'share', channel, confirmed!==false ? 1 : 0);
		    },

		    like: function (channel, liked) {
		      // Analytics.track.event("social", liked ? "like" : "unlike", channel);
		    },

		    init: function () {

		      fn.shareURL = window.location.href;
		      fn.shareURLencoded = encodeURIComponent(fn.shareURL);

		      $('body').on('click', '.twitter', function (e) {
		        e.preventDefault();
		        fn.init.twitter();
		        fn.urlShare($(this).attr("href"), fn.channel.twitter);
		      });

		      $('body').on('click', '.mail', function (e) {
		        fn.init.mail()
		        Social.share(fn.channel.mail, false);
		      });

		      $('body').on('click', '.facebook', function (e) {
		        e.preventDefault();
		        fn.init.facebook();

		        var url = $(this).attr("href");

		        if(fn.sharer) fn.urlShare(url, fn.channel.facebook);
		        else window.FB.ui({
		          method: 'share',
		          href: url
		        }, function(response){
		          Social.share(fn.channel.facebook, true);
		        });
		      });

		    },

		    refresh: function () {
		      fn.init.twitter();
		      fn.init.facebook();
		      fn.init.mail();
		    }

		  };

		  if (FB){
		    FB.Event.subscribe('edge.create', function(response) {
		      Social.like(fn.channel.facebook, true);
		      $(window).trigger("facebook:liked");
		    });

		    FB.Event.subscribe('edge.remove', function(response) {
		      Social.like(fn.channel.facebook, false);
		    });
		  }

		  if (twttr){
		    twttr.ready(function (twttr) {
		        twttr.events.bind('tweet', function () {
		          Social.share(fn.channel.twitter);
		        });
		    });
		  }

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {
			Social.init();
		}

		__construct();


		//
		// Make some events accessible from global scope
		//
		return {

		};
	};


})(jQuery, jQuery);
