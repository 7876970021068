(function($, w, undefined) {
    function ProductImageGallery(args) {
        this.defaults = {
            elem: '.product-image-gallery',
            mainSwiperElem: '.swiper-container.main',
            thumbsSwiperElem: '.swiper-container.thumbnails'
        };

        this.options = $.extend(true, {}, this.defaults, args);
        this.$elem = $(this.options.elem);

        this.mainSwiper = null;
        this.thumbSwiper = null;

        if(!this.$elem.length > 0){
            return;
        }

        this.initSwipers();

    };

    ProductImageGallery.prototype.parent = function (selector) {
        return $(selector, this.$elem);
    };


    ProductImageGallery.prototype.initSwipers = function (){

        this.thumbSwiper = new Swiper(this.options.thumbsSwiperElem, {
            spaceBetween: 25,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,


            // Responsive breakpoints
            breakpointsInverse: true,
            breakpoints: {
                992: {
                    slidesPerView: 5,
                }
            }
        });

        this.mainSwiper = new Swiper(this.options.mainSwiperElem, {
            spaceBetween: 0,
            thumbs: {
                swiper: this.thumbSwiper
            }
        });
    };


    w.Bio= w.Bio|| {};
    w.Bio.Modules = w.Bio.Modules || {};
    w.Bio.Modules.ProductImageGallery = ProductImageGallery;

})(jQuery, window);
