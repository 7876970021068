var Bio = Bio || {};

(function ($, jQuery) {
	'use strict';

	Bio.MenuDesktop = function (options) {

		var defaults = {

			selectors: {
				body: 'body',
				pageBranch: '.navigation-page-branch',
				navOverlay: '#global-header .nav-overlay'
			},

			classes: {
				togglePageBranch: 'menu-page-branch-active',
				inactiveNav: 'at-rest'
			},

			timeouts: {
				// This is needed to counteract the gap between the menu and the navigation pane.
				closeElementLeave: 500,
				// The time it takes for the css animation to action to complete.
				animationTimeout: 250
			}
		};

		options = $.extend(true, {}, defaults, options);

		/**********************************
		// Declare variables
		**********************************/
		var $body = $(options.selectors.body);
		var $pageBranch = $(options.selectors.pageBranch);
		var $navOverlay = $(options.selectors.navOverlay);

		var animationTimeout = null;

		var active = false;

		window.Bio.Utility.StateManager.addOnEnter('desktop', onBPEnter);
		window.Bio.Utility.StateManager.addOnLeave( 'desktop', onBPLeave);

		function onBPEnter() {
			active = true;
		}

		function onBPLeave() {
			active = false;
		}

		/**********************************
		// Interface Events
		**********************************/
		function openMenu(pageBranch) {
			if(typeof pageBranch.data('id') === 'undefined') {
				pageBranch.data('id', 'page-branch-' + Math.floor(Math.random() * 100000000000));
			}
			clearTimeout(animationTimeout);
			$pageBranch.addClass(options.classes.inactiveNav);
			$body.addClass(options.classes.togglePageBranch);
			pageBranch.removeClass(options.classes.inactiveNav);
			pageBranch.addClass(options.classes.togglePageBranch);
			$navOverlay.removeClass(options.classes.inactiveNav);

		}

		function closeMenu(pageBranch) {
			// closeTimeouts[pageBranch.data('id')] = setTimeout(function() {
				$body.removeClass(options.classes.togglePageBranch);
				pageBranch.removeClass(options.classes.togglePageBranch);

				animationTimeout = setTimeout(function() {
					$pageBranch.addClass(options.classes.inactiveNav);
					$navOverlay.addClass(options.classes.inactiveNav);
				}, options.timeouts.animationTimeout);
			// }, options.timeouts.closeElementLeave);

		}

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents() {
			$pageBranch.mouseenter(function (event) {
				if(!active) {
					return;
				}
				var selectedPageBranch = $(event.target).parents(options.selectors.pageBranch);
				if(selectedPageBranch.length === 0) {
					selectedPageBranch = $(event.target);
				}
				openMenu(selectedPageBranch);
			});

			$pageBranch.mouseleave(function (event) {
				if(!active) {
					return;
				}
				var selectedPageBranch = $(event.target).parents(options.selectors.pageBranch);
				if(selectedPageBranch.length === 0) {
					selectedPageBranch = $(event.target);
				}
				closeMenu(selectedPageBranch);
			});
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init
			bindEvents();

		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
			//'sampleEvent': sampleEvent,
		};
	};


})(jQuery, jQuery);