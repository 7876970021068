(function($, w, undefined) {
    function NotificationBanner(args) {
        this.defaults = {
            elem: '#notification-banner',
            btnClose: '.btn-close',
            cookieName: 'BioIsland.GlobalAlertClosed'
        };

        this.options = $.extend(true, {}, this.defaults, args);


        this.$elem = $(this.options.elem);

        if(!this.$elem.length > 0){
            return;
        }

        /*
         Define nodes / elements
         */
        this.$body = $('body');
        this.$btnClose = $(this.parent(this.options.btnClose));
        var self = this;

        let CookieCheck = Cookies.get(this.options.cookieName);

        if(CookieCheck === undefined){
            self.showBanner();
        }

        this.$btnClose.click(function (event) {
            self.closeBanner();
        });
    };

    NotificationBanner.prototype.parent = function (selector) {
        return $(selector, this.$elem);
    };

    NotificationBanner.prototype.saveCookie = function (value){
        Cookies.set(this.options.cookieName, value, { expires: 1 });
    };

    NotificationBanner.prototype.showBanner = function (){
        this.$elem.addClass('active');
        this.$body.addClass('notification-active');
    };

    NotificationBanner.prototype.closeBanner = function (){
        this.saveCookie(true);
        this.$elem.removeClass('active');
        this.$body.removeClass('notification-active');
    };


    w.Bio= w.Bio|| {};
    w.Bio.Modules = w.Bio.Modules || {};
    w.Bio.Modules.NotificationBanner = NotificationBanner;

})(jQuery, window);
